









































import {Component, Vue} from "vue-property-decorator";
import PayrollFileDTO from "@/dto/payroll/PayrollFileDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PayrollService from "@/services/PayrollService";
import PayrollFileCategories from "@/constants/PayrollFileCategories";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import {namespace} from "vuex-class";
import FileUpload from "@/components/common/FileUpload.vue";
import SavePayrollFileRequest from "@/dto/payroll/SavePayrollFileRequest";
import CompanyService from "@/services/CompanyService";
import CompanyDTO from "@/dto/company/CompanyDTO";
import UploadForCompanyModal from "@/components/payroll/UploadForCompanyModal.vue";
import RouteNames from "@/router/RouteNames";
import PayrollDocumentActionModal from "@/components/util/DocumentActionModal.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  components: {FileUpload}
})
export default class BusinessPayrollDetails extends Vue {

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Auth.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  private message = "";

  private successful = true;

  private forms: Array<PayrollFileDTO> = [];

  private employee = new PersonDTO(); // mock to prevent continuous null checking

  private company = new CompanyDTO(); // mock to prevent continuous null checking

  mounted() {
    if (!this.$route.params.personId) {
      this.$router.push({
        name: RouteNames.PAYROLL_BUSINESS_EMPLOYEES,
        params: {companyId: this.$route.params.companyId, then: PayrollFileCategories.DETAILS}
      });
    } else {
      this.loadPerson().then(
          () => this.loadCompany().then(
              () => this.loadForms()
          )
      );
    }
  }

  loadPerson() {
    if (!this.hasAdministrativePrivileges) {
      return Promise.resolve();
    }
    this.startLoading();
    return PersonService.getById(Number.parseInt(this.$route.params.personId, 10)).then(
        response => {
          this.successful = true;
          this.stopLoading();
          this.employee = response.data;
        },
        error => {
          this.stopLoading();
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
    );
  }

  loadForms() {
    this.startLoading();
    return PayrollService.getPayrollFilesByCategoryAndEmployeeIdAndEmployerId(
        PayrollFileCategories.DETAILS, this.getEmployeeId, this.getEmployerId).then(
        (response) => {
          this.successful = true;
          this.forms = response.data;
        },
        (error) => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
    ).then(() => this.stopLoading());
  }

  loadCompany() {
    this.startLoading();
    return CompanyService.getById(Number.parseInt(this.$route.params.companyId, 10)).then(
        response => {
          this.successful = true;
          this.stopLoading();
          this.company = response.data;
        },
        error => {
          this.stopLoading();
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
    );
  }

  onFilesUploaded(files: FileMetaDTO[], employerId: number, period: string) {
    files.forEach(f => {
      PayrollService.savePayrollFile(new SavePayrollFileRequest(this.employee.id as number, employerId, period, f, PayrollFileCategories.DETAILS)).then(
          response => {
            this.forms.push(response.data);
          }
      );
    });
  }

  showUploadModal() {
    this.$modal.show(
        UploadForCompanyModal,
        {
          onFilesUploaded: this.onFilesUploaded,
          company: this.company
        }
    );
  }

  get getEmployerId() {
    return Number.parseInt(this.$route.params.companyId, 10);
  }

  get getEmployeeId() {
    return Number.parseInt(this.$route.params.personId, 10);
  }

  onDocumentClick(document: PayrollFileDTO) {
    if (this.hasAdministrativePrivileges) {
      this.showActions(document);
    } else {
        Vue.prototype.$docUtils.download(document.file.id);
    }
  }

  showActions(document: PayrollFileDTO) {
    this.$modal.show(
        PayrollDocumentActionModal,
        {
          onDelete: () => this.deleteDocument(document),
          onDownload: () => Vue.prototype.$docUtils.download(document.file.id)
        },
        {width: "50%", height: "auto"}
    );
  }

  deleteDocument(document: PayrollFileDTO) {
    this.message = "";
    this.startLoading();
    PayrollService.deleteDocument(document.id, 'PAYROLL_FILE').then(
        response => {
          this.message = "Deleted";
          this.successful = true;
          this.stopLoading();
          this.loadForms();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

}
